/*------------------------------------*\
  Trumbs > Layout
\*------------------------------------*/

.u-inline {
  display: inline;
}

.u-block {
  display: block;
}

.u-inline-block {
  display: inline-block;
}

.u-left {
  float: left;
}

.u-right {
  float: right;
}