/*------------------------------------*\
  Components > Footer
\*------------------------------------*/

.c-footer {
  margin-top: double($base-spacing-unit);
  padding: double($base-spacing-unit) 0 double($base-spacing-unit) 0;
  border-top: 1px solid $color-border;

  @include mq($from: desktop) {
    margin-top: quadruple($base-spacing-unit);
    padding: (quadruple($base-spacing-unit) + $base-spacing-unit) 0 $base-spacing-unit 0;
  }

  &__headline {
    margin-bottom: $base-spacing-unit + half($base-spacing-unit);
    color: $color-gray;
    letter-spacing: $global-letter-spacing;
    font-weight: $font-weight-regular;
    text-transform: uppercase;
  }

  &__bottom {
    margin-top: $base-spacing-unit;
    padding-top: double($base-spacing-unit);
    border-top: 1px solid $color-border;

    @include mq($from: desktop) {
      margin-top: $base-spacing-unit;
    }
  }

  &__copyright {
    color: $color-gray;

    a {
      color: $color-gray;

      @include attention() {
        color: $color-brand;
      }
    }

    @include mq($until: tablet) {
      text-align: center;
      margin-bottom: half($base-spacing-unit);
    }
  }
}

.c-footer-list {
  margin-bottom: double($base-spacing-unit);

  li {
    display: block;
    margin: 0;
    letter-spacing: 0;
    text-transform: capitalize;

    &:not(:last-child) a {
      margin-bottom: half($base-spacing-unit);
      padding-bottom: half($base-spacing-unit);
    }
  }

  a {
    display: block;
    padding: 0;
    font-weight: $font-weight-regular;
    font-size: $font-size-small;

    @include attention() {
      color: $color-brand;

      .icon {
        fill: $color-brand;
      }
    }
  }

  .icon {
    fill: $color-gray;
    vertical-align: middle;
    transition: $global-transition;
  }
}