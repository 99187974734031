// *----------------------------------*\
//  Settings > Colors
// *----------------------------------*/

// Colors
$color-brand: #000000;
$color-white: #FFFFFF;
$color-black: #000000;
$color-text:  #222222;
$color-gray:  #5D738D;

// Background Colors
$bg-gray:         #F6F9FC;
$bg-gray-dark:    $color-gray;
$bg-white:        $color-white;
$bg-dark-opacity: rgba($color-black, .1);

// Border
$color-border: #EBF1F8;

// Links
$color-link: $color-text;