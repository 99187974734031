/*------------------------------------*\
  Components > Related
\*------------------------------------*/

.c-related {
  padding: double($base-spacing-unit) 0 0;

  @include mq($from: desktop) {
    padding: triple($base-spacing-unit) 0 double($base-spacing-unit) 0;
  }

  &__title {
    padding-bottom: 0;
    margin-bottom: double($base-spacing-unit);
    text-align: center;
    font-size: $font-size-base;

    @include mq($from: desktop) {
      margin-bottom: triple($base-spacing-unit);
      font-size: $font-size-h3;
    }
  }

  .c-post-card {
    @include mq($from: desktop) {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}