/*------------------------------------*\
  Elements > Page
\*------------------------------------*/

body {
  color: $color-text;
  font-size: $font-size-medium;
  font-family: $font-sans-serif;
  line-height: $line-height-base;
  background-color: $color-white;

  -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

*::selection {
  color: $color-white;
  background-color: $color-brand;
}