// *----------------------------------*\
// Tools > Mixins
// *----------------------------------*/

// Apply ‘attention’ styles in one go.
// https://goo.gl/LFx8on
//
// Example use:
//
// @include attention() {
//   text-decoration: underline;
// }

@mixin attention() {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}

// Mixin to drop micro clearfix into a selector.
//
// // Example use:
//
// .class {
//   @include clearfix();
// }

@mixin clearfix() {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

// Mixin to target IE
//
// // Example use:
//
// @include ie() {
//   background-color: #000;
// };
@mixin ie {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}