/*------------------------------------*\
  Elements > Fonts
\*------------------------------------*/

//
// Playfair Display
//

/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/2NBgzUtEeyB-Xtpr9bm1CRw5vVFbIG7DatP53f3SWfE.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/2NBgzUtEeyB-Xtpr9bm1CaH_fTF-WHdxjXJZkfhNjc4.woff2) format('woff2');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/2NBgzUtEeyB-Xtpr9bm1CSVudZg2I_9CBJalMPResNk.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/2NBgzUtEeyB-Xtpr9bm1CRD8Ne_KjP89kA3_zOrHj8E.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

//
// Jost*
//

@font-face {
  font-family: 'Jost*';
  src: url('https://indestructibletype-fonthosting.github.io/jost-700-bold.woff2') format('woff2'),
       url('https://indestructibletype-fonthosting.github.io/jost-700-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Jost*';
  src: url('https://indestructibletype-fonthosting.github.io/jost-700-bolditalic.woff2') format('woff2'),
       url('https://indestructibletype-fonthosting.github.io/jost-700-bolditalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Jost*';
  src: url('https://indestructibletype-fonthosting.github.io/jost-600-semi.woff2') format('woff2'),
       url('https://indestructibletype-fonthosting.github.io/jost-600-semi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Jost*';
  src: url('https://indestructibletype-fonthosting.github.io/jost-600-semiitalic.woff2') format('woff2'),
       url('https://indestructibletype-fonthosting.github.io/jost-600-semiitalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Jost*';
  src: url('https://indestructibletype-fonthosting.github.io/jost-500-medium.woff2') format('woff2'),
       url('https://indestructibletype-fonthosting.github.io/jost-500-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Jost*';
  src: url('https://indestructibletype-fonthosting.github.io/jost-500-mediumitalic.woff2') format('woff2'),
       url('https://indestructibletype-fonthosting.github.io/jost-500-mediumitalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Jost*';
  src: url('https://indestructibletype-fonthosting.github.io/jost-400-book.woff2') format('woff2'),
       url('https://indestructibletype-fonthosting.github.io/jost-400-book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Jost*';
  src: url('https://indestructibletype-fonthosting.github.io/jost-400-bookitalic.woff2') format('woff2'),
       url('https://indestructibletype-fonthosting.github.io/jost-400-bookitalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Jost*';
  src: url('https://indestructibletype-fonthosting.github.io/jost-300-light.woff2') format('woff2'),
       url('https://indestructibletype-fonthosting.github.io/jost-300-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Jost*';
  src: url('https://indestructibletype-fonthosting.github.io/jost-300-lightitalic.woff2') format('woff2'),
       url('https://indestructibletype-fonthosting.github.io/jost-300-lightitalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}