/*------------------------------------*\
  Elements > img
\*------------------------------------*/

img {
  display: block;
  max-width: 100%;
  font-style: italic;
}

figcaption {
    font-style: italic;
    color: $color-gray;
}

figure {
    margin-bottom: 10px;
    padding-bottom: 10px;
}
