/*------------------------------------*\
  Components > Social Icons / Footer
\*------------------------------------*/

.c-social-icons {
  text-align: center;

  @include mq($from: tablet) {
    text-align: right;
  }

  li {
    display: inline-block;
  }

  .icon {
    fill: $color-black;
  }
}