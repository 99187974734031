/*------------------------------------*\
  Objects > Wrapper
\*------------------------------------*/

/**
 * Sticky footer
 */
.c-off-canvas-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .o-wrapper { flex: 1 0 auto; }
}