/*------------------------------------*\
  Components > Off Canvas
\*------------------------------------*/

$off-canvas-width: 300px;

.c-off-canvas-toggle {
  position: absolute;
  top: double($base-spacing-unit) + half($base-spacing-unit);
  right: $base-spacing-unit + half($base-spacing-unit);
  z-index: 10;
  height: $base-spacing-unit + half($base-spacing-unit);
  width: $base-spacing-unit + half($base-spacing-unit);
  cursor: pointer;

  @include mq($from: desktop) {
    display: none;
  }

  &__icon {
    position: absolute;
    left: 0;
    height: 1px;
    width: 25px;
    background: $color-black;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      display: block;
      height: 100%;
      background-color: inherit;
      transition: $global-transition;
    }

    &:before { transform: translateY(16px) }
    &:after { transform: translateY(7px); }

    .c-off-canvas-toggle--close & {
      height: 2px;
      background-color: transparent;

      &:before,
      &:after {
        position: relative;
        visibility: visible;
        background: $color-black;
      }

      &:before {
        top: 11px;
        transform: rotate(-45deg);
      }

      &:after {
        top: 9px;
        transform: rotate(45deg);
      }
    }
  }

  &--close {
    @include mq($until: desktop) {
      top: double($base-spacing-unit);
    }
  }
}

// prevent horizontal scroll during off-canvas

html,
body {
  overflow-x: hidden;
}

.c-off-canvas-content {
  @include mq($from: desktop) {
    position: relative;
  }
}

@include mq($until: desktop) {
  .c-off-canvas-content {
    position: fixed;
    top: 0;
    right: - quarter($base-spacing-unit);
    z-index: 1;
    width: $off-canvas-width;
    height: 100vh;
    overflow-y: auto;
    padding: double($base-spacing-unit) (double($base-spacing-unit) + half($base-spacing-unit));
    background-color: $bg-white;
    transform: translate3d($off-canvas-width, 0, 0);

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      &.is-active {
        right: $off-canvas-width + half($base-spacing-unit);
      }
    }
  }

  .c-off-canvas-container {
    -webkit-perspective: 1000;
    transition: transform $global-transition cubic-bezier(.565, 1.65, .765, .88);

    &.is-active {
      transform: translate3d(- ($off-canvas-width + quarter($base-spacing-unit)), 0, 0);

      &:after {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        background-color: rgba(0, 0, 0, .2);
      }
    }
  }
}