/*------------------------------------*\
  Elements > Links
\*------------------------------------*/

a {
  text-decoration: none;
  color: $color-link;

  @include attention() {
    color: $color-brand;
    text-decoration: none;
  }
}