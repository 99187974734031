/*------------------------------------*\
  Components > Header
\*------------------------------------*/

.c-header {
  padding: double($base-spacing-unit) 0;

  &__inner {
    display: flex;
    align-items: center;
  }
}