/*------------------------------------*\
  Components > Dropcap
\*------------------------------------*/

// All posts edited or saved with the Koenig editor enabled are wrapped in an element with .kg-post
// https://forum.ghost.org/t/koenig-editor-beta-release/1284

.c-content,
.kg-post {
  > p:first-child {
    &::first-letter {
      float: left;
      height: 42px;
      margin-top: 6px;
      margin-right: half($base-spacing-unit);
      font-size: 66px;
      line-height: 48px;
      font-style: normal;
      font-family: $font-sans-serif;
    }

    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }
}