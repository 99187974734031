/*------------------------------------*\
  Components > Post
\*------------------------------------*/

.c-post {
  max-width: 700px;
  margin: 0 auto;
  padding-top: $base-spacing-unit + half($base-spacing-unit);

  @include mq($from: desktop) {
    padding-top: double($base-spacing-unit) + half($base-spacing-unit);
  }
}

.PageNavigation {
    font-size: 14px;
    display: block;
    width: auto;
    overflow: hidden;
  }
  
  .PageNavigation a {
    display: block;
    width: 50%;
    float: left;
    margin: 1em 0;
  }
  
  .PageNavigation .next {
    text-align: right;
  }
  
  .pipe-list span:not(:first-child) {
    &::before {
        content: " | ";
      }
  }