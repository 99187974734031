/*------------------------------------*\
  Components > Subscribe Form
\*------------------------------------*/

.c-subscribe-form {
  background-color: $bg-white;
  border: 1px solid $color-black;
  display: flex;
  flex-wrap: wrap;

  .form-group {
    flex: 8;
    margin-right: $base-spacing-unit;
  }

  &__btn {
    flex: 2;
  }

  &__btn {
    border-color: transparent;
  }

  &__input[type='email'] {
    padding-left: ($base-spacing-unit + half($base-spacing-unit));
    border: 0;
    margin: 0;

    &:focus {
      border-color: transparent;
    }
  }
}