// *----------------------------------*\
//  Settings > Type
// *----------------------------------*/

$font-serif:      'Playfair Display', Georgia, serif;
$font-sans-serif: 'Jost*', Helvetica, Arial, sans-serif;
$font-monospace:  Courier, monospace;

$font-size-base:   20px;
$font-size-medium: 16px;
$font-size-small:  14px;
$font-size-tiny:   12px;

$font-weight-regular: 400;
$font-weight-medium:  600;
$font-weight-bold:    700;

$line-height-base:   1.5;